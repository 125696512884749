import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import MovingTickets from '../home/MovingTickets'
import SubscriptionListItem from './SubscriptionListItem'
import style from '../../assets/scss/layouts/card.style.module.scss'
import { CardItem } from './SubscriptionCards'
import ResponsiveQuery, { useMediaQuery } from 'react-responsive'
import Addmrh from './modals/Addmrh'
import AddPno from './modals/AddPno'
import { homeAction } from '../../redux/actions'

function Subscription() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })
  const {
    mrhData,
    mrhDataLoading,
    pnoDataLoading,
    pnoData,
    petsOffersData,
    target_residence,
  } = useSelector((state) => state.home)
  const [addMrh, setAddMrh] = useState(false)
  const [addPno, setAddPno] = useState(false)
  const legalProtection = [
    "L'habitat",
    'La santé',
    'La consommation',
    "L'administration",
    'Le travail',
    'La construction',
    'Le recouvrement des impayés',
    'Les personnes',
  ]
  const roadAutomobile = [
    'Remboursement de votre franchise auto',
    'Remboursement du stage de récupération de points',
    'Assistance retour au domicile',
    'Perte financière',
  ]

  // handle close for modals
  const showMrhModalHandler = () => {
    setAddMrh((prev) => !prev)
  }
  const showPnoModalHandler = () => {
    setAddPno((prev) => !prev)
  }

  const [selectedProviders, setSelectedProviders] = useState([])

  useEffect(() => {
    if (target_residence === 'mrh') setSelectedProviders(mrhData?.providers)
    else if (target_residence === 'pno')
      setSelectedProviders(pnoData?.providers)
    else dispatch(homeAction.updateTarget('mrh'))
  }, [target_residence, pnoData, mrhData, dispatch])

  return (
    <div className="subscription">
      <Addmrh open={addMrh} handleClose={showMrhModalHandler} />
      <AddPno open={addPno} handleClose={showPnoModalHandler} />
      <div className="subTabs">
        <SubscriptionListItem title={t('Home Insurance')} setToOpen={true}>
          <div className={style.index}>
            {mrhData && (
              <div data-style="section">
                <div data-style="actions" data-after={pnoData ? 'true' : ''}>
                  {isMobile ? (
                    <>
                      {target_residence === 'mrh' && mrhData?.fake ? (
                        <button onClick={showMrhModalHandler}>
                          {t('Test with my address')}
                        </button>
                      ) : target_residence === 'pno' && pnoData?.fake ? (
                        <button onClick={showPnoModalHandler}>
                          {t('Test with my address')}
                        </button>
                      ) : (
                        <></>
                      )}
                      <select
                        value={target_residence}
                        onChange={(e) =>
                          dispatch(homeAction.updateTarget(e.target.value))
                        }
                      >
                        <option value="mrh">{t('Principal residence')}</option>
                        <option value="pno">{t('Secondary residence')}</option>
                      </select>
                    </>
                  ) : (
                    <>
                      <p>{t('Principal residence')}</p>
                      {mrhData?.fake && (
                        <button onClick={showMrhModalHandler}>
                          {t('Test with my address')}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <Loader
                  loading={mrhDataLoading || (isMobile && pnoDataLoading)}
                >
                  <div data-style="cards">
                    {isMobile
                      ? selectedProviders?.map((doc, i) => {
                          const price = doc.cheapest
                            ? parseInt(doc.cheapest)
                            : 0
                          if (!price) return <></>
                          return (
                            <CardItem
                              type={'mrh'}
                              cheapest={doc.cheapest}
                              iconPath={doc.iconPath}
                              colorCode={doc.colorCode}
                              name={doc.name}
                              offers={doc.offers}
                              doc={doc}
                              key={`principalResidence-${doc.name}-${i}`}
                            />
                          )
                        })
                      : mrhData?.providers?.map((doc, i) => {
                          const price = doc.cheapest
                            ? parseInt(doc.cheapest)
                            : 0
                          if (!price) return <></>
                          return (
                            <CardItem
                              type="mrh"
                              cheapest={doc.cheapest}
                              iconPath={doc.iconPath}
                              colorCode={doc.colorCode}
                              name={doc.name}
                              offers={doc.offers}
                              doc={doc}
                              key={`principalResidence-${doc.name}-${i}`}
                            />
                          )
                        })}
                  </div>
                </Loader>
              </div>
            )}
            <ResponsiveQuery minWidth={'650px'}>
              {pnoData ? (
                <div data-style="section">
                  <div data-style="actions">
                    <p>{t('Secondary residence')}</p>
                    {pnoData.fake ? (
                      <button onClick={showPnoModalHandler}>
                        {t('Test with my address')}
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Loader loading={pnoDataLoading}>
                    <div data-style="cards">
                      {pnoData?.providers?.map((doc, i) => {
                        const price = doc.cheapest ? parseInt(doc.cheapest) : 0
                        if (!price) return <></>
                        return (
                          <CardItem
                            type="pno"
                            cheapest={doc.cheapest}
                            iconPath={doc.iconPath}
                            colorCode={doc.colorCode}
                            name={doc.name}
                            offers={doc.offers}
                            doc={doc}
                            key={`secondaryResidence-${doc.name}-${i}`}
                          />
                        )
                      })}
                    </div>
                  </Loader>
                </div>
              ) : (
                <></>
              )}
            </ResponsiveQuery>
          </div>
        </SubscriptionListItem>
        <SubscriptionListItem title={t('Legal Protection')} setToOpen={true}>
          <div className={style.legalCard}>
            <div className={style.myContainer}>
              <div className={style.innerContainer}>
                <p>Frais de justice pris en charge jusqu'à 15.000 €</p>
                <div className={style.nestedContainer}>
                  <p>Couvre les litiges suivants :</p>
                  <ul className={style.grid}>
                    {legalProtection.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <CardItem
                type="PJ"
                cheapest={'6.50'}
                iconPath={'/icons/mutuelle.png'}
                colorCode={'#b5e61d'}
                offers={[
                  {
                    url: 'https://www.assurancemutuelle.com/devis/demande-de-devis-protection-juridique-privee',
                    price: 6.5,
                  },
                ]}
              />
            </div>
          </div>
        </SubscriptionListItem>
        <SubscriptionListItem
          title={t('Tranquil Road Automobile')}
          setToOpen={true}
        >
          <div className={style.legalCard}>
            <div className={style.myContainer}>
              <div className={style.innerContainer}>
                <p>Complémentaire à votre assurance auto</p>
                <div className={style.nestedContainer}>
                  <p>Principales garanties (Pour 2 vehicules et 2 permis)</p>
                  <ul className={style.flex}>
                    {roadAutomobile.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <CardItem
                type="RTA"
                cheapest={8}
                iconPath={'/icons/mutuelle.png'}
                colorCode={'#b5e61d'}
                offers={[
                  {
                    url: 'https://www.assurancemutuelle.com/devis/demande-de-devis-route-tranquille',
                    price: 8,
                  },
                ]}
              />
            </div>
          </div>
        </SubscriptionListItem>
        <SubscriptionListItem title={t('Assurance scolaire')} setToOpen={true}>
          <div className={style.legalCard}>
            <div className={style.myContainer}>
              <div className={style.innerContainer}>
                <p>Scolaires et Extrascolaires</p>
                <div className={style.nestedContainer}>
                  <p>
                    Assurez l’avenir de vos enfants à chaque étape de leur
                    parcours scolaire avec notre partenaire Malj, de la crèche à
                    l’université, avec les 3 formules d’assurance scolaire sur
                    mesure !
                  </p>
                  <p>Et profitez d'une réduction avec le code SCOLAIRE2024 !</p>
                </div>
              </div>
              <CardItem
                type="AS"
                cheapest={17}
                iconPath={'/icons/mutuelle.png'}
                colorCode={'#b5e61d'}
                offers={[
                  {
                    url: 'https://souscription.assurancemutuelle.com/scolaire-tarif?d=1c4484c110227c2fd7dc570cb000265f14d14a74',
                    price: 17,
                  },
                ]}
              />
            </div>
          </div>
        </SubscriptionListItem>
        <SubscriptionListItem title={t('Pet Plan')} setToOpen={true}>
          <div className={style.index}>
            <div data-style="section">
              {!!petsOffersData?.length &&
                petsOffersData.map((doc) => (
                  <>
                    <div data-style="actions">
                      <p>Pet Name: {doc.petName}</p>
                    </div>
                    <div data-style="cards">
                      <CardItem
                        type={'pet'}
                        cheapest={doc.price}
                        iconPath={doc?.iconUrl || '/icons/lukoIcon.svg'}
                        colorCode={doc?.colorCode || '#00D3C8'}
                        name={doc.name}
                        offers={[
                          { price: doc.price, url: doc.subscriptionUrl },
                        ]}
                      />
                    </div>
                  </>
                ))}
            </div>
          </div>
        </SubscriptionListItem>
        <SubscriptionListItem
          title={t('Car insurance')}
          subtitle={t('Coming Soon!')}
        />
      </div>
      <div className="movingRequestTab card_news">
        <MovingTickets />
      </div>
    </div>
  )
}

export default Subscription
