import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space, Spin, Radio } from 'antd'
import { BASE_URL } from '../../constant'
import { setFilteredOffer } from '../../redux/home/home.actions'
import { useTranslation } from 'react-i18next'
import Swal from 'sweetalert2'
import style from '../../assets/scss/layouts/card.style.module.scss'
import 'sweetalert2/src/sweetalert2.scss'
import { Icon } from '@iconify/react'
import { homeAction } from '../../redux/actions'
import WarningIcon from '../../components/Icons/Warning'
import { Tooltip } from '@mui/material'
import { getCurrentTime } from '../../utils/connectycube'
import { ClickAwayListener } from '@mui/material'
import { useScreenSize } from '../../hooks/use-screen'

function SubscriptionCards() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { offers, offersCopy, loading } = useSelector((state) => state.home)
  const [filter, setFilter] = useState('All')
  const fakeOffersData = [
    {
      category: 'Insurance',
      products: [
        {
          name: 'pno',
          cheapest: '6.23',
          providers: [
            {
              name: 'luko',
              colorCode: '#0042DA',
              iconPath: '/icons/lukoIcon.svg',
              cheapest: '6.23',
              offers: [],
            },
            {
              name: 'lovys',
              colorCode: '#00D3C8',
              iconPath: '/icons/lovysIcon.png',
              cheapest: '7.01',
              offers: [],
            },
          ],
        },
        {
          name: 'mrh',
          cheapest: '5.15',
          providers: [
            {
              name: 'luko',
              colorCode: '#0042DA',
              iconPath: '/icons/lukoIcon.svg',
              cheapest: '5.15',
              offers: [],
            },
            {
              name: 'lovys',
              colorCode: '#00D3C8',
              iconPath: '/icons/lovysIcon.png',
              cheapest: '5.51',
              offers: [],
            },
          ],
        },
      ],
      cheapest: '5.15',
      colorCode: '#167EB8',
      providerList: ['/icons/lukoIcon.svg', '/icons/lovysIcon.png'],
    },
  ]
  const { t } = useTranslation()
  const onSelectOffer = (e) => {
    setFilter(e.target.value.toUpperCase())
    const fakeData = localStorage.getItem('fakeData')
    const dataToPass = fakeData === 'true' ? fakeOffersData : offers

    dispatch(setFilteredOffer(dataToPass, e.target.value))
  }
  const onMobSelectOffer = (selectedOffer) => {
    setFilter(selectedOffer.key.toUpperCase())
    const fakeData = localStorage.getItem('fakeData')
    const dataToPass = fakeData === 'true' ? fakeOffersData : offers

    dispatch(setFilteredOffer(dataToPass, selectedOffer.key))
  }
  const menu = (
    <Menu
      style={{ left: 12 }}
      onClick={onMobSelectOffer}
      items={[
        {
          label: <span>{t('All')}</span>,
          key: 'All',
        },
        {
          label: <span>{t('Principal residence')}</span>,
          key: 'mrh',
        },
        {
          label: <span>{t('Secondary residence')}</span>,
          key: 'pno',
        },
      ]}
    />
  )
  return (
    <div className="SubscriptionCards">
      <div className="TabsHeader">
        <BsArrowLeft
          className="back-icon"
          onClick={() => navigate('/home', { state: '1' })}
        />
        <h2 style={{ textAlign: 'center', marginBottom: 0 }}>
          {t('Insurance')}
        </h2>
        <div className="filtersGroup">
          <Radio.Group size="large" value={filter} onChange={onSelectOffer}>
            <Radio.Button value="All">{t('All')}</Radio.Button>
            <Radio.Button value="mrh">{t('Principal residence')}</Radio.Button>
            <Radio.Button value="pno">{t('Secondary residence')}</Radio.Button>
          </Radio.Group>
        </div>
        <div className="czx893">
          <div className="TabsDropdown">
            <Dropdown className="subDropdown" menu={menu} trigger={['click']}>
              <Link to="#">
                <Space id="spaceAll">
                  {filter}
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          </div>
        </div>
      </div>
      {loading ? (
        <Spin />
      ) : (
        <div className="companyCards">
          {offersCopy.map((offer, index) => {
            return offer.products.map((elem) => {
              return elem.providers.map((elem) => {
                return (
                  <div
                    className="ComCard"
                    style={{ backgroundColor: elem.colorCode }}
                  >
                    <div className="ComCardHead">
                      <div className="ComLogo">
                        <img
                          src={`${BASE_URL}${elem.iconPath}`.replace(
                            '/api/',
                            '/'
                          )}
                          alt="img"
                        />
                      </div>
                      {/* <span>{t(`${offer.category}`)}</span> */}
                    </div>
                    <div className="ComCardContent">
                      <p>{t('From')}:</p>
                      <div className="ComPrice">
                        <span>
                          {elem.cheapest}€ /{t('month')}
                        </span>
                      </div>
                      <button
                        target="_blank"
                        onClick={() =>
                          elem.offers.length > 0
                            ? navigate('/plans', { state: elem })
                            : Swal.fire({
                                icon: 'info',
                                title: t(
                                  'Please complete your profile to get relevant offers'
                                ),
                                showCancelButton: true,
                                cancelButtonText: t('Cancel'),
                                confirmButtonText: t("Let's complete now"),
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate('/preference')
                                }
                              })
                        }
                      >
                        {t('See Plans')}
                      </button>
                    </div>
                  </div>
                )
              })
            })
          })}
        </div>
      )}
    </div>
  )
}

export const CardItem = ({ colorCode, iconPath, cheapest, offers, type }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const isMobile = useScreenSize()
  const { user } = useSelector((state) => state.users)

  const handleTooltipClick = () => {
    setOpen(!open)
  }
  const redirectToExternalLink = (externalLink) => {
    window.open(externalLink, '_blank')
  }
  const handleAdjustOffer = () => {
    const regex = /(lovys|assurancemutuelle|luko)/
    if (offers.length > 0) {
      const analyticsData = {
        product: type,
        price: offers[0].price,
        url: offers[0].url,
        provider:
          offers[0].url.match(regex)[0] === 'assurancemutuelle'
            ? 'malj'
            : offers[0].url.match(regex)[0],
        time: getCurrentTime(),
        user: user.email,
      }
      dispatch(homeAction.setClickCounter(analyticsData))
      redirectToExternalLink(offers[0].url)
    } else
      Swal.fire({
        icon: 'info',
        title: t('Please complete your profile to get relevant offers'),
        showCancelButton: true,
        cancelButtonText: t('Cancel'),
        confirmButtonText: t("Let's complete now"),
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(homeAction.setTab('1'))
        }
      })
  }

  return (
    <div className={style.card} style={{ backgroundColor: colorCode }}>
      <div className={style.wrapperFlex}>
        <div data-style="badge-img">
          <img src={`${BASE_URL}${iconPath}`.replace('/api/', '/')} alt="img" />
        </div>
        {iconPath.includes('mutuelle') && (
          <>
            {isMobile ? (
              <ClickAwayListener onClickAway={handleTooltipClick}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClick}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Suite aux récentes catastrophes naturelles, vérifiez si votre commune est toujours assurée en parcourant l'intégralité du trajet"
                  placement="top"
                >
                  <div className="flex sm:hidden" onClick={handleTooltipClick}>
                    <WarningIcon />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            ) : (
              <Tooltip
                title="Suite aux récentes catastrophes naturelles, vérifiez si votre commune est toujours assurée en parcourant l'intégralité du trajet"
                placement="top-start"
              >
                <div className="flex sm:hidden">
                  <WarningIcon />
                </div>
              </Tooltip>
            )}
          </>
        )}
      </div>

      <p data-style="title">A partir de :</p>
      <p data-style="price">
        {cheapest}€ / {type === 'AS' ? 'an' : 'mois'}{' '}
      </p>
      <button target="_blank" onClick={handleAdjustOffer}>
        Ajuster l'offre{' '}
        <Icon icon="material-symbols:keyboard-double-arrow-right-rounded" />
      </button>
    </div>
  )
}

export default SubscriptionCards
